import React, { useContext } from 'react';
// import 'animate.css';
import { Link } from 'gatsby';
import { Row, Col, Stack } from 'react-bootstrap';
import { Header, Footer } from 'components/theme';
import { Container, Button } from 'components/common';
import servicepaketelarge from 'assets/illustrations/SERVICEPAKETE-LARGE.svg';
import element51 from 'assets/illustrations/Element51.png';
import element52 from 'assets/illustrations/Element52.png';
import fairies from 'assets/illustrations/FAIRIES1.svg';
import CookieConsent from 'react-cookie-consent';
import {
  PrivatKundenWrapper,
  PrivatWrapper,
  PrivatKundenWrapperDetails,
  HeroImage,
  ImageBackground,
  Section1Wrapper,
  Section2Wrapper,
  Section3Wrapper,
  Section4Wrapper,
  Section5Wrapper,
  Section4Background,
  Section5Background,
} from '../components/styles/styles';

export default function Privatkunden() {
  function getContactForm() {
    const id = document.getElementById('kontaktButton');
    if (id) {
      document.getElementById('homeButton').click();
      setTimeout(function() {
        document.getElementById('kontaktButton').click();
      }, 0);
    }
  }
  return (
    <>
      <PrivatKundenWrapper>
        <Header />
        <HeroImage>
          <PrivatWrapper as={Container} className="positionRelative">
            <PrivatKundenWrapperDetails>
              <Stack gap={3}>
                <Row className="animate__animated animate__slideInDown">
                  <Col>
                    <h4 className="title1">PRIVAT</h4>
                  </Col>
                </Row>
                <Row className="animate__animated animate__slideInDown">
                  <Col>
                    <h4 className="title2">KUNDEN</h4>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <a href="mailto:office@rd-hausfee.at">
                      <Button className="buttonAnfrage">ANFRAGE</Button>
                    </a>
                    <Link to="/">
                      <Button className="buttonAngebot" onClick={getContactForm}>
                        ANGEBOT EINHOLEN
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Stack>
            </PrivatKundenWrapperDetails>
            <Row className="positionAbsolute">
              <img
                className="servicepakete animate__animated animate__flipInX"
                src={servicepaketelarge}
                alt="Servicepakete"
                height="263"
              />
            </Row>
          </PrivatWrapper>
        </HeroImage>
        <PrivatWrapper as={Container} className="positionRelative">
          <Section1Wrapper>
            <div className="sectionP1">
              <h2 className="dancingScript64Privat">
                Wir legen wert auf den menschlichen Aspekt und <br /> stützen unseren Erfolg auf unsere Fähigkeiten.
              </h2>
              <p className="latoRegular36Privat">
                Wir bieten kompetente Reinigung und Assistenz im Alltag. Wir lieben es Menschen zu unterstützen und zu
                umsorgen. Unser Herz geht auf, wenn wir Ihr Zuhause zu einem Ort voller Sicherheit und Wohlbefinden
                machen. Unser Glück ist es, Ihnen ein Lächeln ins Gesicht zu zaubern. Was sollen wir sagen? Wir sind
                eben gute Feen.
              </p>
            </div>
          </Section1Wrapper>
        </PrivatWrapper>
        <ImageBackground>
          <PrivatWrapper as={Container} className="positionRelative">
            <Section2Wrapper>
              <Row>
                <Col>
                  <h3 className="dancingScript56Privat">
                    Sie wünschen sich <br /> Hilfe im Haushalt?
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md="6" xs="12">
                  <p className="latoRegular21WhitePrivat">
                    Gewissenhaft übernehmen wir alle Aufgaben <br /> rund um Ihren Haushalt. (Reinigung aller <br />
                    Räume, Fenster- und Türenreinigung, <br /> Detailreinigung, Erledigen von Einkäufen, <br />{' '}
                    Wäscheservice und vieles mehr.)
                  </p>
                </Col>
                <Col md="6" xs="12">
                  <p className="latoRegular21WhitePrivat">
                    Wir kümmern uns auch gerne um <br /> Treppenhaus, Keller, Garage und den <br /> Straßenbereich vor
                    Ihrem Zuhause. <br />
                    Maßgeschneiderte Servicepakete bieten <br /> kostengünstig genau das, was Sie brauchen.
                  </p>
                </Col>
              </Row>
            </Section2Wrapper>
          </PrivatWrapper>
        </ImageBackground>
        <PrivatWrapper as={Container} className="positionRelative">
          <Section3Wrapper>
            <div className="box"></div>
            <Row>
              <Col md="6" sm="8" xs="12" lg="4" className="marginRight53">
                <img className="element51" src={element51} alt="Ironing" />
              </Col>
              <Col md="6" xs="12">
                <div>
                  <h3 className="latoRegular36Privat paddingLeft53">
                    Sie haben <br /> Bügelwäsche?
                  </h3>
                  <p className="latoRegular21Privat paddingLeft53">
                    {' '}
                    Kein Problem, wir sind schon dabei. Fein säuberlich gebügelt und <br /> geordnet landet Ihre Wäsche
                    und sauber wieder im Schrank. <br /> Hygienische Wäsche trägt maßgeblich zur Gesundheit bei und{' '}
                    <br /> unterstützt Ihr Wohlbefinden.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="paddingTop20">
              <Col md="6" xs="12">
                <img className="element52" src={element52} alt="Linen" />
              </Col>
              <Col md="6" xs="12" className="section3Right">
                <h3 className="latoRegular36Privat">
                  Sie sehnen sich <br /> nach Ordnung?
                </h3>
                <p className="latoRegular21Privat">
                  Wir verschaffen uns erst einen Überblick und konzipieren dann genau das Ordnungssystem, das zu Ihnen
                  passt. Dank cleverer Lösungen profitieren Sie und müssen nie wieder lange nach etwas suchen.
                </p>
              </Col>
            </Row>
          </Section3Wrapper>
        </PrivatWrapper>
        <Section4Background>
          <PrivatWrapper as={Container} className="paddingBottom53">
            <Section4Wrapper>
              <Stack>
                <div>
                  <Stack className="paddingTop160">
                    <div>
                      <h2 className="dancingScript64PrivatWhite">
                        Wir sehen unsere Kunden nicht als Zahlen, <br /> Perspektiven oder Jobs, sondern als Menschen.
                      </h2>
                      <p className="latoRegular36White">
                        Sie haben Fragen zu unseren Leistungen oder wünschen sich sofort eine gute Fee? Vereinbaren Sie
                        noch heute ein kostenloses Erstgespräch!
                      </p>
                    </div>
                  </Stack>
                  <Stack className="positionRelative alignCenter">
                    <Button className="buttonKontaktPrivat col-md-5 mx-auto" onClick={getContactForm}>
                      KONTAKT
                    </Button>
                    <img className="fairies1Privat" src={fairies} alt="Fairies" />
                  </Stack>
                </div>
              </Stack>
            </Section4Wrapper>
          </PrivatWrapper>
        </Section4Background>
        <Section5Background>
          <Footer />
        </Section5Background>
      </PrivatKundenWrapper>
      <CookieConsent
        location="bottom"
        buttonText="Ich stimme zu"
        cookieName="myAwesomeCookieName2"
        style={{ background: '#95c357' }}
        buttonStyle={{ color: '#95c357', fontSize: '13px', background: '#fff' }}
      >
        Diese Website verwendet Cookies – nähere Informationen dazu und zu Ihren Rechten als Benutzer finden Sie in
        unserer <a href="/impressum">Datenschutzerklärung</a> am Ende der Seite. Klicken Sie auf „Ich stimme zu“, um
        Cookies zu akzeptieren.
      </CookieConsent>
    </>
  );
}
